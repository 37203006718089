// playerSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const playerSlice = createSlice({
  name: "player",
  initialState: {
    currentSongId: null,
    isPlaying: false,
    volume: 1,
    duration: 0,
    currentTime: 0,
    repeat: false,
  },
  reducers: {
    setPlaybackState: (state, action) => {
      state.duration = action.payload.duration || state.duration;
      state.currentTime = action.payload.currentTime || state.currentTime;
    },
    setVolume: (state, action) => {
      state.volume = action.payload;
    },
    togglePlayPause: (state) => {
      state.isPlaying = !state.isPlaying;
    },
    setRepeat: (state, action) => {
      state.repeat = action.payload;
    },
    setPlayingState: (state, action) => {
      state.isPlaying = action.payload;
    },
    setCurrentSongId: (state, action) => {
      state.currentSongId = action.payload;
    },
  },
});

export const {
  setPlaybackState,
  setVolume,
  togglePlayPause,
  setRepeat,
  setPlayingState,
  setCurrentSongId,
} = playerSlice.actions;
export const selectPlayer = (state) => state.player;
export default playerSlice.reducer;
