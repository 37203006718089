// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../api/axiosConfig";

const API_URL = "http://localhost:8000";


const initialState = {
  user: null,
  isAuthenticated: false,
  isLoading: false,
  error: null,
};
export const checkAuthentication = createAsyncThunk("auth/checkAuthentication", async (_, { dispatch }) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error("No token found");
    }
    const response = await api.get('/check-auth/');
    console.log("repons Authentification",response.data)
    if (response.data.isAuthenticated) {
      dispatch(loginSuccess(response.data.user)); // Dispatch loginSuccess with user data only
    } else {
      dispatch(logout());
    }
  } catch (error) {
    console.error("Authentication check failed:", error);
    dispatch(logout());
  }
});

export const loginUser = createAsyncThunk("auth/loginUser", async (credentials, { dispatch }) => {
  try {
    dispatch(loginStart());
    const response = await api.post('/login/', credentials);
    const { user, token } = response.data;

    localStorage.setItem('token', token);
    dispatch(loginSuccess({ user, token }));
    return { user, token };
  } catch (error) {
    console.error("Login failed:", error.response.data);
    dispatch(loginFailure(error.toString()));
    throw error;
  }
});

export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async (_, { dispatch }) => {
    try {
      localStorage.removeItem('token');

      // Perform any asynchronous logout tasks here, such as clearing session data on the server
      // For example, you can make an API request to log out the user on the server
      await api.post('/logout/');
      // Dispatch the logout success action
      dispatch(logout());
    } catch (error) {
      // Dispatch the logout failure action if an error occurs
      console.error('Logout failed:', error);
      throw error;
    }
  }
);

export const registerUser = createAsyncThunk("auth/registerUser", async (userData, { dispatch }) => {
  try {
    dispatch(registerStart());
    const response = await api.post('/register/', userData);
    const { user, token } = response.data;

    localStorage.setItem('token', token);
    dispatch(registerSuccess({ user, token }));
    return { user, token };
  } catch (error) {
    console.error("Registration failed:", error.response.data);
    dispatch(registerFailure(error.toString()));
    throw error;
  }
});

  export const uploadProfilePicture = createAsyncThunk("profile/uploadPicture", async (file) => {
    try {
        const formData = new FormData();
        formData.append('avatar', file);

        const response = await api.post('/upload-profile-picture/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        console.log("File uploaded successfully:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
    }
  });
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    loginFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
    },
    registerStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    registerSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    registerFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
      builder.addCase(logoutUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      });
      builder.addCase(logoutUser.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuthenticated = false; // Mark the user as not authenticated
        state.user = null;
      });
      builder.addCase(logoutUser.rejected, (state) => {
        state.isLoading = false;
        // Handle logout failure if needed
      });
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  registerStart,
  registerSuccess,
  registerFailure,
} = authSlice.actions;

export const selectUser = (state) => state.auth?.user;
export const selectIsAuthenticated = (state) => state.auth?.isAuthenticated;
export const selectLoading = (state) => state.auth?.isLoading;
export const selectError = (state) => state.auth?.error;

export default authSlice.reducer;
