import { Heart, MoreVertical, Pause, Play } from "lucide-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectPlayer } from "../../features/player/playerSlice";
import { musicI } from "../../assets";
import SoundWave from "../soundWave/soundWave";

export function Song({ order, song, name, onSongClick }) {
  const [isHovered, setIsHovered] = useState(false);
  const { currentSongId, isPlaying } = useSelector(selectPlayer);

  return (
    <div
      className={`grid grid-cols-3 text-gray-500 py-4 dark:hover:bg-[#24303F] hover:bg-slate-300 rounded-lg cursor-pointer pr-3 pl-3 ${
        isHovered ? "hovered" : ""
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-center space-x-4">
        {isHovered ? (
          song.id === currentSongId && isPlaying ? (
            <span onClick={onSongClick}>
              <Pause />
            </span>
          ) : (
            <span onClick={onSongClick}>
              <Play />
            </span>
          )
        ) : song.id === currentSongId && isPlaying ? (
         <span><SoundWave /></span> 
        ) : (
          <p>{order + 1}</p>
        )}
        <img
          className="h-10 w-10"
          src={musicI} // Replace with your actual image source
          alt=""
        />
        <div>
          <p className="w-36 lg:w-64 text-white truncate">{song.title}</p>
          <p className="w-40">{song.artist}</p>
        </div>
      </div>
      <div className="flex items-center">
        <p className="w-40 hidden md:inline">{name}</p>
      </div>
      <div className="flex items-center justify-end gap-4 ml-auto md:ml-0">
        <Heart />
        <p>{song.duration}</p>
        <MoreVertical />
      </div>
    </div>
  );
}
