import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  musicList: [],
  status: 'idle',
  error: null,
};

// Async Thunk for fetching music
export const fetchMusic = createAsyncThunk('music/fetchMusic', async () => {
  const response = await axios.get('/api/music');
  return response.data;
});

// Async Thunk for adding new music
export const addMusic = createAsyncThunk('music/addMusic', async (musicData) => {
  const formData = new FormData();
  formData.append('music_file', musicData.musicFile); // Assuming you have a music file to upload

  // Add other fields of musicData as needed

  const response = await axios.post('/api/music', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
});

// Async Thunk for updating existing music
export const updateMusic = createAsyncThunk('music/updateMusic', async (musicData) => {
  const response = await axios.put(`/api/music/${musicData.id}`, musicData);
  return response.data;
});

// Async Thunk for deleting music
export const deleteMusic = createAsyncThunk('music/deleteMusic', async (musicId) => {
  await axios.delete(`/api/music/${musicId}`);
  return musicId;
});

const musicSlice = createSlice({
  name: 'music',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMusic.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchMusic.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.musicList = action.payload;
    },
    [fetchMusic.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addMusic.fulfilled]: (state, action) => {
      state.musicList.push(action.payload);
    },
    [updateMusic.fulfilled]: (state, action) => {
      const index = state.musicList.findIndex((music) => music.id === action.payload.id);
      if (index !== -1) {
        state.musicList[index] = action.payload;
      }
    },
    [deleteMusic.fulfilled]: (state, action) => {
      state.musicList = state.musicList.filter((music) => music.id !== action.payload);
    },
  },
});

export default musicSlice.reducer;

export const selectMusicList = (state) => state.music.musicList;
export const selectMusicStatus = (state) => state.music.status;
export const selectMusicError = (state) => state.music.error;
