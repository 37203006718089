import React from "react";

const Card = ({icon,title,subtitle}) => {
  return (
    <div className="rounded-sm  border-[#E2E8F0]  py-6 px-7.5 shadow-default dark:border-[#2E3A47] dark:bg-[#24303F]">
      <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-[#EFF2F7] dark:bg-[#313D4A]">
        {icon}
      </div>

      <div className="mt-4 flex items-end justify-between">
        <div>
          <h4 className="text-title-md font-bold text-black dark:text-white">
            $3.456K
          </h4>
          <span className="text-sm font-medium">Total views</span>
        </div>

        <span className="flex items-center gap-1 text-sm font-medium text-[#10B981]">
          0.43%
          <svg
            className="fill-meta-3"
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
              fill=""
            />
          </svg>
        </span>
      </div>
    </div>
  );
};

export default Card;
