import React, { useState } from "react";
import { Beat, Card, ChartOne, ChartTwo, ChartThree } from "../../components";
import { icon1,icon2,icon3,icon4 } from "../../assets/svg/icon";
import { songs } from "../../utils/songs";
import { useDispatch, useSelector } from "react-redux";
import { selectPlayer, setCurrentSongId, setPlayingState, togglePlayPause } from "../../features/player/playerSlice";


const Dashboard = () => {
  const [selectedSong, setSelectedSong] = useState(null);
  const dispatch = useDispatch();
  const { isPlaying, currentSongId } = useSelector(selectPlayer);
  const [playingSongId, setPlayingSongId] = useState(null);

  const handleSongPlay = (clickedSong) => {
    setSelectedSong(clickedSong);

    if (currentSongId === clickedSong.id) {
      // If the clicked song is the currently playing song, toggle play/pause
      dispatch(togglePlayPause());
    } else {
      // If a new song is clicked, update the current song and play it
      dispatch(setCurrentSongId(clickedSong.id));
      dispatch(setPlayingState(true)); // Set to true to play the new song
    }
  };
  const icons = [icon1, icon2, icon3, icon4]
  
  return (
    <div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        {icons.map((icon)=><Card icon={icon}/>)}
      </div>
      <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
        <ChartOne/>
        <ChartTwo/>
        <ChartThree/>


      </div>
      <div className="min-h-screen mt-4">
        <h3 className="text-4xl dark:text-slate-50 mb-4">
          Recent Played
        </h3>
        <div className="flex flex-wrap gap-2">
        {songs.songs.map((song, index) => (
            <Beat
              key={index}
              song={song}
              type={songs.type}
              onSongClick={() => handleSongPlay(song)}
              isPlaying={isPlaying && currentSongId === song.id}
            />
          ))}
     
      </div>
      </div>
    </div>
  );
};

export default Dashboard;
