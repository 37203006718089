import React, { useState } from "react";
import { musicI } from "../../assets";


const MediaItem = ({ data, onClick }) => {
  // const imageUrl = useLoadImage(data);

  /**
   * Handles click events on the media item.
   */
  const handleClick = () => {
    if (onClick) {
      onClick(data?.id);
    }
    // TODO: Default turn on player
  };

  return (
    <div
      onClick={handleClick}
      className="
        flex
        items-center
        cursor-pointer
        w-full
        rounded-md
        mr-2
        ml-2
      "
    >
      <div
        className="
          relative
          rounded-md
          min-h-[48px]
          min-w-[48px]
          overflow-hidden
        "
      >
        {/* <Image
          fill
          src={imageUrl || "/images/liked.png"}
          alt="Media Item"
          className="object-cover"
        /> */}
        <img src={musicI} alt="" srcset="" className="w-20 h-15"/>
      </div>
      <div
        className="
        flex
        flex-col
        gap-y-1
        overflow-hidden
      "
      >
        <p className="text-white truncate">{data?.title}</p>
        <p className="text-neutral-400 text-sm truncate">{data?.author}</p>
      </div>
    </div>
  );
};
export default MediaItem;
