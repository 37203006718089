import { configureStore } from '@reduxjs/toolkit'
import themeReducer from '../features/theme/themeSlice'
import userSlice from '../features/user/userSlice'
import playerSlice from '../features/player/playerSlice'
export default configureStore({
  reducer: {
    theme:themeReducer,
    auth:userSlice,
    player:playerSlice,
  }
})