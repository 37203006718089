export const album = [
  {
    "id": 1,
    "title": "Awesome album",
    "backgroundImageUrl": "https://example.com/playlist1.jpg",
    "type": "album",
    "description": "A collection of awesome songs",
    "createdBy": "John Doe",
    "releaseDate": "2022-01-01",
    "songs": [
      {
        "title": "Song 1",
        "artist": "Artist 1",
        "duration": "3:30"
      },
      {
        "title": "Song 2",
        "artist": "Artist 2",
        "duration": "4:15"
      },
      {
        "title": "Song 3",
        "artist": "Artist 3",
        "duration": "5:00"
      }
    ]
  },
  {
    "id": 2,
    "title": "Chill Vibes",
    "backgroundImageUrl": "https://example.com/playlist2.jpg",
    "type": "album",
    "description": "Relax and unwind with these calming tunes",
    "createdBy": "Jane Smith",
    "releaseDate": "2022-02-15",
    "songs": [
      {
        "title": "Relaxing Song",
        "artist": "Calming Artist",
        "duration": "4:45"
      },
      {
        "title": "Soothing Melody",
        "artist": "Tranquil Musician",
        "duration": "3:30"
      },
      {
        "title": "Zen Vibes",
        "artist": "Mindful Composer",
        "duration": "5:15"
      }
    ]
  },
  {
    "id": 3,
    "title": "Epic Album",
    "backgroundImageUrl": "https://example.com/album1.jpg",
    "type": "album",
    "description": "An epic journey through sound",
    "createdBy": "Epic Music Productions",
    "releaseDate": "2022-03-20",
    "genre": "Epic",
    "songs": [
      {
        "title": "Epic Overture",
        "duration": "6:30"
      },
      {
        "title": "Heroic Theme",
        "duration": "5:45"
      },
      {
        "title": "Majestic Finale",
        "duration": "8:00"
      }
    ]
  }
  // Add more album objects as needed
];
