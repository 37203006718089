import React from 'react'

const Footer = () => {
  return (
    <footer class="w3l-footer9">
    <div class="footer-inner-main py-5">
        <div class="container mx-auto sm:px-4 lg:pt-12 md:pt-6 pt-2 pb-2">
            <div class="flex flex-wrap ">
                <div class="lg:w-1/3 pr-4 pl-4 sub-one-left lg:pe-12 pt-5">
                    <h6>About </h6>
                    <p class="footer-phny">Lorem ipsum viverra feugiat. Pellen tesque libero ut justo,
                        ultrices in ligula. Semper at tempufddfel.Lorem ipsum dolor sit,l. Lorem ipsum dolor sit,
                        amet elit.</p>
                    <div class="columns-2 lg:mt-12 mt-4">
                        <ul class="social">
                            <li><a href="#facebook"><span class="fab fa-facebook-f"></span></a>
                            </li>
                            <li><a href="#linkedin"><span class="fab fa-linkedin-in"></span></a>
                            </li>
                            <li><a href="#twitter"><span class="fab fa-twitter"></span></a>
                            </li>
                            <li><a href="#google"><span class="fab fa-google-plus-g"></span></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="lg:w-1/5 pr-4 pl-4 w-1/2 sub-two-right lg:mt-0 mt-5">
                    <h6>Links</h6>
                    <ul>
                        <li><a href="index.html">Home</a> </li>
                        <li><a href="about.html">About Us</a> </li>
                        <li><a href="classes.html">Classes</a> </li>
                        <li><a href="#blog">Blog Posts</a> </li>
                        <li><a href="contact.html">Contact</a></li>
                    </ul>
                </div>
                <div class="lg:w-1/5 pr-4 pl-4 w-1/2 sub-two-right lg:mt-0 mt-5">
                    <h6>Explore</h6>
                    <ul>
                        <li><a href="#support">Support</a></li>
                        <li><a href="#privacypolicy">Privacy policy</a></li>
                        <li><a href="#offers">Offers</a></li>
                        <li><a href="#support">Support</a></li>
                        <li><a href="#career">Careers</a></li>
                    </ul>
                </div>
                <div class="lg:w-1/4 pr-4 pl-4 sub-one-left lg:mt-0 mt-5">
                    <h6>Twitter Feed</h6>
                    <ul>
                        <li class="w3tweet-holder-grids">
                            <div class="w3-twitter-icon"><i class="fab fa-twitter"></i></div>
                            <div class="w3tweet-text">
                                <a target="_blank" href="#twitter">@Honynilf</a> Hi <a target="_blank"
                                    href="#twitter">@Honynilf</a> , can you please submit a ticket at <a
                                    target="_blank" href="#twitter">https://v.co/ij123A34J45A</a> <br/>
                                1 year ago.
                            </div>
                        </li>
                        <li class="w3tweet-holder-grids">
                            <div class="w3-twitter-icon"><i class="fab fa-twitter"></i></div>
                            <div class="w3tweet-text">
                                <a target="_blank" href="#twitter">@Honynilf</a> Hi <a target="_blank"
                                    href="#twitter">@Honynilf</a> , can you please submit a ticket at <a
                                    target="_blank" href="#twitter">https://v.co/ij123A34J45A</a> and one of our
                                support agent… <a target="_blank" href="#twitter">https://v.co/ij123A34J45A</a>
                                2 year ago.
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="copyright-footer lg:mt-12 sm:mt-6 mt-2 text-center">
                <p class="copy-text">&copy; 2023 BMusic. All rights reserved.
                </p>
            </div>
        </div>
    </div>
</footer>
  )
}

export default Footer