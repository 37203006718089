// utils/screenSizeUtils.js
import { useState, useEffect } from 'react';

const getScreenSize = () => {
  const width = window.innerWidth;

  if (width < 576) {
    return 'xs';
  } else if (width < 768) {
    return 'sm';
  } else if (width < 992) {
    return 'md';
  } else if (width < 1200) {
    return 'lg';
  } else {
    return 'xl';
  }
};

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState(getScreenSize);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize());
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Initialize screen size on mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Remove the dependency array

  return screenSize;
};

export default useScreenSize;
