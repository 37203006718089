import React from "react";
import { Carousel } from "../components";
import { about, about4, img1, img2, img3, img4 } from "../assets";
const Home = () => {
  return (
    <div>
      {/* banner section */}
      <Carousel />

      {/* //banner section */}
      {/* about section */}
      <section className="w3l-about py-5" id="about">
        <div className="container mx-auto sm:px-4 lg:py-12 md:py-6 py-2">
          <div className="flex flex-wrap  items-center">
            <div className="lg:w-1/2 pr-4 pl-4 section-width xl:pe-12 lg:pt-6">
              <h3 className="title-style">
                Bienvenue chez <span>B-Music</span>
              </h3>
              <p className="mt-4">
                B-Music est la plateforme où les rêves musicaux prennent vie.
                Explorez nos opportunités uniques pour les auteurs de chansons
                et les chanteurs professionnels, et plongez dans un monde de
                collaboration musicale sans limites.
              </p>
              {/* <p class="mt-4 pb-dm-3"> plongez
                    dans un monde de collaboration musicale sans limites.</p> */}
              <a
                href="about.html"
                className="btn btn-style mt-5"
              >
                En savoir plus sur nous
              </a>
            </div>
            <div className="lg:w-1/2 pr-4 pl-4 history-info lg:mt-0 mt-5 md:pt-6 pt-2">
              <div className="relative img-border">
                <img
                  src={about}
                  className="max-w-full h-auto radius-image"
                  alt="video-popup"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* //about section */}
      {/* classes section */}
      <section className="w3l-classes py-5" id="classes">
        <div className="container mx-auto sm:px-4 lg:py-12 md:py-6 py-2">
          <h3 className="title-style text-center">
            BMusic <span>ce que vous trouverez</span>
          </h3>
          <p className="text-para found">
            Découvrez notre collection exclusive de beats qui transcendent les
            frontières musicales. Chaque rythme est une symphonie soigneusement
            orchestrée, fusionnant
          </p>
          <div className="flex flex-wrap  text-center justify-center">
            <div className="lg:w-1/3 pr- md:w-1/2 pr-4 pl-4 lg:mt-12 mt-4">
              <div className="grids-1 box-wrap lg:p-4">
                <div className="icon">
                  <i className="fas fa-music" />
                </div>
                <h4>
                  <a href="#service" className="title-head mt-4 mb-2">
                    Piano
                  </a>
                </h4>
                <p className="text-para">
                  Les beats au piano apportent une touche de sophistication.
                </p>
              </div>
            </div>
            <div className="lg:w-1/3  md:w-1/2 pr-4 pl-4 lg:mt-12 mt-4">
              <div className="grids-1 box-wrap lg:p-4">
                <div className="icon active">
                  <i className="fas fa-guitar" />
                </div>
                <h4>
                  <a href="#service" className="title-head mt-4 mb-2">
                    Guitare
                  </a>
                </h4>
                <p className="text-para">
                  les accords de guitare ajoutent une dimension chaleureuse
                </p>
              </div>
            </div>
            <div className="lg:w-1/3 md:w-1/2 pr-4 pl-4 lg:mt-12 mt-4">
              <div className="grids-1 box-wrap lg:p-4">
                <div className="icon">
                  <i className="fas fa-drum" />
                </div>
                <h4>
                  <a href="#service" className="title-head mt-4 mb-2">
                    trompette
                  </a>
                </h4>
                <p className="text-para">
                  l'énergie vibrante de la trompette.
                </p>
              </div>
            </div>
            {/* <div class="lg:w-1/3 pr-4 pl-4 md:w-1/2 pr-4 pl-4 mt-4">
                <div class="grids-1 box-wrap lg:p-4">
                    <div class="icon">
                        <i class="fas fa-compact-disc"></i>
                    </div>
                    <h4><a href="#service" class="title-head mt-4 mb-2">DJ</a></h4>
                    <p class="text-para">Plongez dans un monde où chaque note résonne avec une émotion distincte</p>
                </div>
            </div>
            <div class="lg:w-1/3 pr-4 pl-4 md:w-1/2 pr-4 pl-4 mt-4">
                <div class="grids-1 box-wrap lg:p-4">
                    <div class="icon">
                        <i class="fas fa-play"></i>
                    </div>
                    <h4><a href="#service" class="title-head mt-4 mb-2">Musics</a></h4>
                    <p class="text-para">Lorem ipsum dolor sit amet, elit. Id ab commodi magnam fugiat accusamus
                        eos. </p>
                </div>
            </div>
            <div class="lg:w-1/3 pr-4 pl-4 md:w-1/2 pr-4 pl-4 mt-4">
                <div class="grids-1 box-wrap lg:p-4">
                    <div class="icon">
                        <i class="fas fa-headphones-alt"></i>
                    </div>
                    <h4><a href="#service" class="title-head mt-4 mb-2">Listening</a></h4>
                    <p class="text-para">Lorem ipsum dolor sit amet, elit. Id ab commodi magnam fugiat accusamus
                        eos. </p>
                </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* //classes section */}
      {/* homeblock1 section */}
      <section className="w3l-homeblock1 py-5">
        <div className="container mx-auto sm:px-4 lg:py-12 md:py-6 py-2">
          <div className="flex flex-wrap  items-center">
            <div className="lg:w-2/5 pr-4 pl-4">
              <h3 className="title-style">
                Sérénité Instrumentale, Des <span>Beats uniques</span> pour
                chaque passion musicale.
              </h3>
              <h6 className="sub-title md:mt-12 mt-4">
                Que vous soyez un chanteur cherchant l'accompagnement parfait,
                un auteur de paroles en quête d'inspiration, ou simplement un
                amateur de sonorités exquises, nos beats avec piano, guitare, et
                trompette offrent une expérience auditive exceptionnelle.
              </h6>
              <a
                href="about.html"
                className="btn btn-style mt-5"
              >
                Voir nos Beats
              </a>
            </div>
            <div className="lg:w-3/5 pr-4 pl-4 lg:mt-0 mt-5 lg:ps-12">
              <div className="flex flex-wrap ">
                <div className="w-1/2">
                  <div className="column-w3-img relative pr-4">
                    <a href="#image">
                      <img
                        src={img1}
                        alt=""
                        className="radius-image max-w-full h-auto"
                      />
                    </a>
                  </div>
                  <div className="column-w3-img relative mt-6 pr-4  ">
                    <a href="#image">
                      <img
                        src={img3}
                        alt=""
                        className="radius-image max-w-full mt-4"
                      />
                    </a>
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="column-w3-img relative">
                    <a href="#image">
                      <img
                        src={img4}
                        alt=""
                        className="radius-image max-w-full h-auto"
                      />
                    </a>
                  </div>
                  <div className="column-w3-img relative mt-4">
                    <a href="#image">
                      <img
                        src={img2}
                        alt=""
                        className="radius-image max-w-full h-auto"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* //homeblock1 section */}
      {/* video section */}
      <section className="w3l-video-sec" id="video">
        <div className="container mx-auto sm:px-4">
          <div className="flex flex-wrap  w3l-video">
            <div className="w-full">
              <div className="videow3-right">
                <div className="w3l-index5 py-5">
                  <div className="history-info align-self text-center py-5">
                    <div className="relative py-5">
                      <a
                        href="#small-dialog1"
                        className="popup-with-zoom-anim play-view text-center absolute"
                      >
                        <span className="video-play-icon">
                          <span className="fa fa-play" />
                        </span>
                      </a>
                      {/* dialog itself, mfp-hide class is required to make dialog hidden */}
                      <div
                        id="small-dialog1"
                        className="zoom-anim-dialog mfp-hide"
                      >
                        <iframe
                          src="https://player.vimeo.com/video/12881072?h=9f0439706"
                          allow="autoplay; fullscreen; picture-in-picture"
                          allowFullScreen
                          frameBorder={0}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* //video section */}
      {/* join section */}
      <section className="w3l-whyblock py-5">
        <div className="container mx-auto sm:px-4 lg:py-12 md:py-6 py-2">
          <div className="flex flex-wrap  items-center">
            <div className="lg:w-1/2 pr-4 pl-4">
              <img
                src={about4}
                alt=""
                className="max-w-full h-auto radius-image"
              />
            </div>
            <div className="lg:w-1/2 pr-4 pl-4 xl:ps-12 lg:ps-6 lg:mt-0 mt-5">
              <h3 className="title-style mb-4">
                {" "}
                <span>Plongez dans l'univers envoûtant de</span> BMusic{" "}
              </h3>
              <p>
                dans l'univers de BMusic chaque note raconte une histoire.
                Découvrez des beats uniques, des textes inspirants, et des
                mélodies captivantes. Laissez-vous emporter par la créativité
                musicale et faites de chaque écoute une expérience
                exceptionnelle.
              </p>
              <div className="two-grids mt-5">
                <div className="grids_info">
                  <i className="fas fa-volume-up" />
                  <div className="detail">
                    <h4>Sorties Exclusives</h4>
                    <p>
                      Soyez parmi les premiers à découvrir des créations
                      musicales exclusives. Nos sorties exclusives offrent un
                      accès privilégié à des compositions originales, des
                      paroles inspirées, et des collaborations uniques. Plongez
                      dans l'extraordinaire et laissez-vous emporter par la
                      nouveauté.
                    </p>
                  </div>
                </div>
                <div className="grids_info xl:mt-12 mt-4">
                  <i className="fas fa-icons" />
                  <div className="detail">
                    <h4>DJ Sets et Podcasts Complets</h4>
                    <p>
                      Immergez-vous dans des mixes envoûtants et des
                      conversations captivantes. Nos DJ sets et podcasts
                      complets vous transportent dans un voyage musical unique,
                      où chaque transition et chaque mot sont une invitation à
                      l'évasion. Découvrez une fusion harmonieuse de beats et
                      d'idées, conçue pour enrichir votre expérience auditive.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* //join section */}
      {/* subscribe form section */}
      <section className="w3l-forms-9 pt-5 lg:pb-6" id="newsletter">
        <div className="container mx-auto sm:px-4">
          <div
            className="main-midd text-center mx-auto"
            style={{ maxWidth: "700px" }}
          >
            <h3 className="title-style mb-2">
              Abonnez-vous <span>à nos mises à jour!</span>
            </h3>
            <p className>
              {" "}
              Entrez votre adresse email pour vous inscrire à notre newsletter
            </p>
            <div
              className="main-midd-2 md:mt-12 mt-4 mx-auto"
              style={{ maxWidth: "600px" }}
            >
              <form
                action="#url"
                method="GET"
                className="sm:flex rightside-form"
              >
                <input
                  type="email"
                  className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                  name="email"
                  placeholder="votre adresse email"
                />
                <button
                  className="btn btn-style"
                  type="submit"
                >
                  Abonné
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* //subscribe form section */}
      {/* button with content section */}
      <section className="w3l-button-sec py-5">
        <div className="container mx-auto sm:px-4 py-5">
          <div className="w3-midhny-sec p-12">
            <div className="flex flex-wrap  items-center md:p-6">
              <div className="xl:w-2/3 lg:w-3/4 pr-4 pl-4">
                <div className="title-content-two lg:pe-12">
                  <h3 className="title-w3l md:mb-1 mb-4 d-mflex items-center">
                    Contactez nous
                    <a className="md:mt-0 mt-2" href="tel:+ 12 123 456 789">
                      + (509) 4446 - 5292
                    </a>
                  </h3>
                  <p>
                    Rejoignez-nous dans cette aventure musicale. Écoutez.
                    Achetez. Vibrez.
                  </p>
                </div>
              </div>
              <div className="xl:w-1/3 pr-4 pl-4 lg:w-1/4  lg:mt-0 sm:mt-12 mt-4 text-lg-end">
                <a
                  href="about.html"
                  className="btn btn-style"
                >
                  Contact Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
