import React, { useEffect, useState } from "react";
import { Beat, Player } from "../../components";
import { songs } from "../../utils/songs";
import { playlist } from "../../utils/playlist";
import { album } from "../../utils/album";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPlayer,
  setCurrentSongId,
  setPlayingState,
  togglePlayPause,
} from "../../features/player/playerSlice";
import { checkAuthentication,selectUser } from "../../features/user/userSlice";

const Homel = () => {
  const [selectedSong, setSelectedSong] = useState(null);
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const { isPlaying, currentSongId } = useSelector(selectPlayer);
  const [playingSongId, setPlayingSongId] = useState(null);

  const handleSongPlay = (clickedSong) => {
    setSelectedSong(clickedSong);

    if (currentSongId === clickedSong.id) {
      // If the clicked song is the currently playing song, toggle play/pause
      dispatch(togglePlayPause());
    } else {
      // If a new song is clicked, update the current song and play it
      dispatch(setCurrentSongId(clickedSong.id));
      dispatch(setPlayingState(true)); // Set to true to play the new song
    }
  };
  useEffect(() => {
    // Dispatch an action to check authentication on component mount
    dispatch(checkAuthentication()); // Replace with your actual checkAuthentication action
  }, []);
  console.log("the user********: ",user)
  return (
    <div className="ml-4">
      {user && <h1 className="text-title-xxl dark:text-slate-50 mb-10">Hi {user.username}</h1>}
      <div className="min-h-screen mt-4">
        <h3 className="text-4xl mb-4 dark:text-slate-50">Popular Beats</h3>
        <div className="flex flex-wrap gap-2">
          {songs.songs.map((song, index) => (
            <Beat
              key={index}
              song={song}
              type={songs.type}
              onSongClick={() => handleSongPlay(song)}
              isPlaying={isPlaying && currentSongId === song.id}
            />
          ))}
        </div>
      </div>

      <div className="min-h-screen mt-4">
        <h3 className="text-4xl mb-4 dark:text-slate-50">Popular Music</h3>
        <div className="flex flex-wrap gap-2">
          {songs.songs.map((song, index) => (
            <Beat
              key={index}
              song={song}
              type={songs.type}
              onSongClick={() => handleSongPlay(song)}
              isPlaying={isPlaying && currentSongId === song.id}
            />
          ))}
        </div>
      </div>
      <div className="min-h-screen mt-4">
        <h3 className="text-4xl mb-4 dark:text-slate-50">Recommendation</h3>
        <div className="flex flex-wrap gap-2">
          {songs.songs.map((song, index) => (
            <Beat
              key={index}
              song={song}
              type={songs.type}
              onSongClick={() => handleSongPlay(song)}
              isPlaying={isPlaying && currentSongId === song.id}
            />
          ))}
        </div>
      </div>
      <div className="min-h-screen mt-4">
        <h3 className="text-4xl mb-4 dark:text-slate-50">Playlist</h3>
        <div className="flex flex-wrap gap-2">
          {playlist.map((song, index) => (
            <Beat
              key={index}
              song={song}
              onSongClick={handleSongPlay}
              data={song}
              type={song.type}
            />
          ))}
        </div>
      </div>
      <div className="min-h-screen mt-4">
        <h3 className="text-4xl mb-4 dark:text-slate-50">Album</h3>
        <div className="flex flex-wrap gap-2">
          {album.map((song, index) => (
            <Beat
              key={index}
              song={song}
              onSongClick={handleSongPlay}
              data={song}
              type={song.type}
            />
          ))}
        </div>
      </div>
      <div className="hidden">
        <Player selectedSong={selectedSong} />
      </div>
    </div>
  );
};

export default Homel;
