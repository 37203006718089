import React, { useState } from "react";

import * as RadixSlider from "@radix-ui/react-slider";
/**
 * @typedef {Object} SliderProps
 * @property {number} [value] - The current value of the slider.
 * @property {(value: number) => void} [onChange] - Callback function for value changes.
 */

/**
 * Slider component.
 * @type {FC<SliderProps>}
 */
const Slider = ({ value, onChange, isVolume, onSliderClick }) => {
  const handleChange = (newValue) => {
    onChange?.(newValue[0]);
  };
  const handleSliderClick = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const percentage = offsetX / rect.width;
    onSliderClick?.(percentage);
  };
  return (
    <RadixSlider.Root
      className="
        relative
        flex
        items-center
        select-none
        touch-none
        w-full
        h-10
        cursor-pointer
      "
      defaultValue={[isVolume ? 50 : 1]}
      value={[value]}
      onValueChange={handleChange}
      min={0}
      max={isVolume ? 100 : 1}
      step={isVolume ? 1 : 0.1}
      aria-label={isVolume ? "Volume" : "Music Timer"}
      onSliderClick={handleSliderClick}
    >
      <RadixSlider.Track
        className="
          dark:bg-neutral-600
          relative
          grow
          rounded-full
          h-[3px]
        "
      >
        <RadixSlider.Range
          className="
            absolute
            dark:bg-white
          bg-sky-600

            rounded-full
            h-full
          "
        />
      </RadixSlider.Track>
    </RadixSlider.Root>
  );
};

export default Slider;
