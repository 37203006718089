import { useState } from "react";
import { selectPlayer, setCurrentSongId, setPlayingState, togglePlayPause, } from "../../features/player/playerSlice";
import { Song } from "../song/song";
import { useDispatch, useSelector } from "react-redux";
import Player from "../player/Player";

export function Songs({songs, name}) {
  const [selectedSong, setSelectedSong] = useState(null);
  const dispatch = useDispatch();
  const { isPlaying, currentSongId } = useSelector(selectPlayer);
  const [playingSongId, setPlayingSongId] = useState(null);
  const handleSongPlay = (clickedSong) => {
    setSelectedSong(clickedSong);

    if (currentSongId === clickedSong.id) {
      // If the clicked song is the currently playing song, toggle play/pause
      dispatch(togglePlayPause());
    } else {
      // If a new song is clicked, update the current song and play it
      dispatch(setCurrentSongId(clickedSong.id));
      dispatch(setPlayingState(true)); // Set to true to play the new song
    }
  };
  return (
    <div className="px-8 flex flex-col space-y-1 pb-28 text-white ">
 {songs.map((song, index) => (
              <Song song={song} name={name} order={index} 
              onSongClick={() => handleSongPlay(song)}
              isPlaying={isPlaying && currentSongId === song.id}
              
              />
          ))}  
           <div className="hidden">
        <Player selectedSong={selectedSong} />
      </div>      
    </div>
  );
}