import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./carousel.module.css"; // Create this file for styling
import { Link } from "react-router-dom";
import { banner1, banner2, banner3, banner4 } from "../../assets";


const images = [
  {
    image:banner1,
    text: "Musique pour tout le monde",
  },
  {
    image:banner2,
    text: "Sens le rythme",
  },
  {
    image:banner3,
    text: "Les meilleurs rythmes de Compas",
  },
  {
    image:banner4,
    text: "laisse des gens savourez votre talent",
  },
  // Add more slides here
];

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className={styles.carouselContainer}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className={styles.carouselSlide}>
            <img
              src={image.image}
              alt={`Slide ${index}`}
              className={styles.carouselImage}
            />
            <div className={styles.textOverlay}>
              <h1 className={`${styles.title} text-white`}>BM Music</h1>
              <h2 className={`${styles.paragraph} text-white`}>
                {image.text}
              </h2>
              <div className={styles.buttonContainer}>
              <Link to="/#" className={`btn btn-style mt-4`}>En savoir plus</Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
